<script setup>
import { inject } from "vue";
//  Получаем функции открытия и закрытия Drawer из App.vue
const drawerState = inject("drawerState");
// const { closeDrawer } = inject("cartActions");
import DrawerSettingsHead from "./DrawerSettingsHead.vue";
import DrawerSettingsBody from "./DrawerSettingsBody.vue";
</script>

<template>
  <div :class="['flex-1', isDrawerVisible ? 'block' : 'hidden md:block']">
    <!-- prettier-ignore -->
    <transition name="fade">
        <!-- prettier-ignore -->
        <div v-if = "drawerState['drawer2']?.isOpen" 
        class="fixed inset-0 z-40 flex opacity-80 cursor-pointer">
            <!-- Over lay -->
            <!-- prettier-ignore -->
            <div class="fixed inset-0 bg-black opacity-80 cursor-pointer"
            @click="closeDrawer('drawer2')">
            </div>
        </div>
    </transition>
  </div>
  <div :class="['flex-1', isDrawerVisible ? 'block' : 'hidden md:block']">
    <transition name="drawer" mode="out-in">
      <!-- prettier-ignore -->
      <div v-if="drawerState['drawer2']?.isOpen" class="fixed inset-0 z-40 flex justify-start">
        <!-- prettier-ignore -->
        <div class="bg-white w-1/4 h-screen fixed inset-0 left-0 top-0 z-20 p-10 shadow-lg p-4
          overflow-y-auto transition-transform transform translate-x-1/6">
          <DrawerSettingsHead />
          <DrawerSettingsBody />
        </div>
      </div>
    </transition>
  </div>
  <!-- prettier-ignore -->
  <div v-if="drawerState['drawer2']?.isOpen" class="md:hidden bg-white w-full h-screen fixed
    inset-0 z-20 p-10">
    <DrawerSettingsHead />
    <DrawerSettingsBody />
    </div>
</template>

<style scoped>
/* prettier-ignore  */
.fade-enter-acitve, .fade-leave-acitve {
  transition: opacity 0.5s ease-in-out;
}
/* prettier-ignore  */
.fade-enter, .fade-leave-to {
  opacity: 0;
}
/* prettier-ignore  */
.fade-enter-to, .fade-leave {
  opacity: 0.5; /* This matches the initial opacity of the overlay */
}

.drawer-enter-active,
.drawer-leave-active {
  transition: all 0.5s ease;
}

.drawer-enter-from,
.drawer-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
