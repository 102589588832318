<script setup>
import { inject } from "vue";
const { closeDrawer } = inject("cartActions");
const drawerState = inject("drawerState");
// Обработчик для закрытия конкретного Drawer
const handleClose = (drawerId) => {
  closeDrawer(drawerId);
};
</script>

<template>
  <!-- prettier-ignore -->
  <div
    v-if="drawerState['drawer2']?.isOpen"
    class="flex inline-block justify-start xl:mt-4 xl:ml-16
    cursor-pointer space-x-4 items-center">
    <!-- prettier-ignore -->
    <svg width="24" height="24" @click="handleClose('drawer2')" 
      viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.9499 24.7254L14.9999 16.7629L7.0499 
        24.7254L5.2749 
        22.9504L13.2374 15.0004L5.2749 7.05039L7.0499 
        5.27539L14.9999 13.2379L22.9499 5.28789L24.7124 
        7.05039L16.7624 15.0004L24.7124 22.9504L22.9499 24.7254Z"
        fill="black"
      />
    </svg>
    <div class="cursor-default">
    <p class="text-base xl:text-xl">
      Закрыть
    </p>
    </div>
  </div>
</template>
