<script setup>
import { inject } from "vue";
const { closeDrawer } = inject("cartActions");
const drawerState = inject("drawerState");
// Обработчик для закрытия конкретного drawer
const handleClose = (drawerId) => {
  closeDrawer(drawerId);
};
</script>

<template>
  <!-- prettier-ignore -->
  <div
    v-if="drawerState['drawer1']?.isOpen"
    class="relative flex justify-between xl:mt-12 mt-10 items-center">
    <div class="text-4xl mx-auto">
      <h2 class="font-bold">Вход</h2>
    </div>
    <!-- prettier-ignore -->
    <div class="cursor-pointer mt-2 absolute xl:right-48 right-4">
      <svg width="30" height="30" @click="handleClose('drawer1')"
        viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.9499 24.7254L14.9999 16.7629L7.0499 
            24.7254L5.2749 
            22.9504L13.2374 15.0004L5.2749 7.05039L7.0499 
            5.27539L14.9999 13.2379L22.9499 5.28789L24.7124 
            7.05039L16.7624 15.0004L24.7124 22.9504L22.9499 24.7254Z"
            fill="black"
          />
      </svg>
    </div>
  </div>
</template>
