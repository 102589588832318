<template>
  <div class="justify-center">
    <h3 class="text-center font-bold text-2xl">Anwalen</h3>
    <p class="text-center xl:mt-6 mt-4 text-xl">Интернет магазин лекал и выкроек</p>
    <div class="flex items-center justify-center xl:mt-2">
      <div class="border-t border-gray-300 xl:mt-4 w-132"></div>
      <span class="px-4 text-center mt-4 text-gray-600">Полезная информация</span>
      <div class="border-t border-gray-300 xl:mt-4 w-132"></div>
    </div>
  </div>
  <!-- prettier-ignore -->
  <div class="grid-cols-5 xl:grid sm:grid-cols-2 lg:grid-cols-[repeat(5,minmax(100px,280px))] 
  gap-x-2 
  mt-4 flex justify-center">
    <div class="text-center">
      <div class="inline-block text-left align-top">
        <p class="font-bold text-xs xl:text-xl">Anwalen</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">О магазине</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">Контакты</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">История</p>
      </div>
    </div>
    <div class="text-center">
      <div class="inline-block text-left align-top">
        <p class="font-bold text-xs xl:text-xl">Покупки</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">Оплата</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">Цены</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">Возврат</p>
      </div>
    </div>
    <div class="text-center">
      <div class="inline-block text-left align-top">
        <p class="font-bold text-xs xl:text-xl">Услуги</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">Скидки</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">Подарочная карта</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">Подарочная упаковка</p>
      </div>
    </div>
    <div class="text-center">
      <div class="inline-block text-left align-top">
        <p class="font-bold text-xs xl:text-xl">Новости</p>
        <p class="mt-4 cursor-pointer hover:underline text-xs xl:text-lg">Новости и события</p>
      </div>
    </div>
    <div class="text-center">
      <div class="inline-block text-left align-top">
        <p class="font-bold text-xs xl:text-xl">Социальные сети</p>
        <div class="mt-4 flex space-x-4 cursor-pointer">
          <a href="#" class="text-gray-700 hover:text-gray-900 ">
            <i class="fas fa-profile">
              <img src="../../public/vk_icon.svg" alt="VK"/>
            </i>
          </a>
          <a href="#" class="text-gray-700 hover:text-gray-900 ">
            <i class="fas fa-profile">
              <img src="../../public/telegram_icon.svg" alt="Telegram"/>
            </i>
          </a>
          <a href="#" class="text-gray-700 hover:text-gray-900 ">
            <i class="fas fa-profile">
              <img src="../../public/whatsapp_icon.svg" alt="Telegram"/>
            </i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
