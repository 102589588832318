<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
    };
  },
  methods: {
    handleSubmit() {
      // Handle form submission
      console.log("Email:", this.email);
      console.log("Password:", this.password);
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<template>
  <!-- prettier-ignore -->
  <div class="flex flex-col justify-center items-center mt-16">
    <div class="relative xl:w-3/5 w-11/12">
      <div class="flex w-full justify-start items-start">
        <label for="email" class="block text-sm font-medium text-gray-700">
          Почта
        </label>
      </div>
      <input
        v-model="email"
        type="email"
        id="email"
        required
        class="mt-1 block w-full px-4 p-4 border border-gray-400 rounded-xl shadow-sm 
        focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
  </div>
  <!-- prettier-ignore -->
  <div class="flex flex-col justify-center items-center mt-10">
    <!-- Нужно что-то придумать с тем чтобы Пароль находился в начали кнопки а не по середине... -->
    <div class="relative xl:w-3/5 w-11/12">
      <div class="flex w-full justify-start items-start">
        <label label for="password" class="block text-sm font-medium text-gray-700">
          Пароль
        </label>
      </div>
      <input
      v-model="password"
      :type="showPassword ? 'text' : 'password'"
      id="password"
      required
      class="mt-1 block w-full px-4 p-4 border border-gray-400 rounded-xl shadow-sm
      focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    />
    <span 
      @click="togglePasswordVisibility"
      class="absolute inset-y-0 mt-6 right-2 pr-3 flex items-center  
      cursor-pointer text-gray-500">
      {{ showPassword ? '👁️' : '🙈' }}  
    </span>
    </div>
  </div>
  <!-- prettier-ignore -->
  <div class="flex flex-col justify-center items-center w-full mt-12">
    <button
    disabled=""
    class="transistion bg-black rounded-2xl p-4 cursor-pointer text-white 
    hover:bg-neutral-800 active:bg-neutral-900 text-center xl:w-3/5 w-11/12"
    >
    Войти
  </button>
  </div>
  <div class="mt-14 border-b"></div>
  <div class="flex flex-col justify-center items-center mt-8 xl:mt-20">
    <div class="xl:w-3/5 w-11/12 text-left font-bold text-2xl">
      <h2>У меня нет аккаунта</h2>
    </div>
    <div class="xl:w-3/5 w-11/12 text-left">
      <h2>Регистрируясь на сайте Anwalen, вы соглашаетесь с политикой конфидициальности.</h2>
    </div>
    <!-- prettier-ignore -->
    <button 
      disabled=""
      type="sumbit"
      id="sumbit"
        class="mt-8 xl:w-3/5 w-11/12 px-4 p-4 border border-black rounded-2xl text-sm 
        font-medium text-black bg-transparent hover:bg-gray-100 active:bg-gray-200 
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
        hover:border-indigo-500 cursor-pointer">
      Создать аккаунт 
    </button>
  </div>
</template>

<style scoped>
/* Add your style */
</style>
