<template>
  <div class="flex justify-center mt-8 xl:mt-20" data-aos="fade-up" data-aos-duration="2000">
    <h2 class="xl:text-4xl text-3xl text-center font-bold">Наш магазин</h2>
  </div>
  <!-- prettier-ignore -->
  <div class="bg-white h-full indent-4 flex flex-col items-center">
    <div class="flex flex-wrap justify-center mt-12 grid gap-8 xl:grid-cols-4 grid-cols-2">
      <!-- Не убирать prettier игнор так как будет вызываться ошибка... -->
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <h3 class="xl:text-2xl text-sm text-center">Платье</h3>
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <h3 class="xl:text-2xl text-sm text-center">Платье</h3>
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <h3 class="xl:text-2xl text-sm text-center">Платье</h3>
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <h3 class="xl:text-2xl text-sm text-center">Платье</h3>
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <h3 class="xl:text-2xl text-sm text-center">Платье</h3>
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <h3 class="xl:text-2xl text-sm text-center">Платье</h3>
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <h3 class="xl:text-2xl text-sm text-center">Платье</h3>
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <h3 class="xl:text-2xl text-sm text-center">Платье</h3>
      </div>
    </div>
  </div>
</template>
