<template>
  <!-- prettier-ignore -->
  <div class="justify-start text-3xl xl:mt-12 xl:ml-16 mt-10 space-y-8 w-auto">
    <div class="cursor-pointer hover:underline"><p>Товар</p></div>
    <div class="cursor-pointer hover:underline"><p>Заказы</p></div>
    <div class="cursor-pointer hover:underline"><p>Цены</p></div>
    <div class="cursor-pointer hover:underline"><p>Отзывы</p></div>
    <div class="cursor-pointer hover:underline"><p>Контакты</p></div>
  </div>
  <div class="mt-14 border-b"></div>
  <!-- prettier-ignore -->
  <div class="xl:mt-28 xl:ml-16 mt-16 space-y-4">
    <p class="text-2xl">Наше место нахождение</p>
    <p class="text-lg">Улица Лацкова, 4 к2 Лацкова м-н, <br>
    Жуковский, Московская область, 140188</p>
  </div>
  <div class="xl:mt-12 xl:ml-16 mt-10 space-y-4">
    <p class="text-2xl">Техническая поддержка</p>
    <p class="text-lg">
      Номер телефона: 89771742301 <br />
      Почта: a.s.yurkov@ruc.su
    </p>
  </div>
</template>
