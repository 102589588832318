<template>
  <!-- prettier-ignore   -->
  <div class="absolute inset-0 flex flex-col justify-center items-center w-full h-full 
  bg-gray-200 overflow-hidden">
    <!-- prettier-ignore   -->
    <p class="xl:text-4xl text-3xl font-bold text-center
    tracking-wider leading-tight xl:mt-4">
      Сделай себя сама
    </p>
    <div class="flex inline-block text-left mt-6 space-y-2 mr-14 space-x-2 items-center">
      <!-- prettier-ignore   -->
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M23 30.1875C26.1756 30.1875 28.75 27.6131 
        28.75 24.4375C28.75 21.2619 26.1756 18.6875 23 
        18.6875C19.8244 18.6875 17.25 21.2619 17.25 24.4375C17.25 
        27.6131 19.8244 30.1875 23 30.1875Z" fill="black"/>
        <path d="M38.8125 12.9375H33.5117C33.2422 12.9375 32.908 
        12.7632 32.6474 12.4883L30.3169
        8.81008C30.28 8.7517 30.2389 8.69614 30.1938 8.64387C29.3888 
        7.705 28.3008 7.1875 27.1328 7.1875H18.8672C17.6992 7.1875 
        16.6112 7.705 15.8062 8.64387C15.7611 8.69614 15.72 8.7517 
        15.6831 8.81008L13.3526 12.4937C13.1531 12.7111 12.8728 12.9429 
        12.5781 12.9429V12.2241C12.5781 11.8429 12.4267 11.4773 12.1571 
        11.2077C11.8875 10.9381 11.5219 10.7866 
        11.1406 10.7866H8.98438C8.60313 10.7866 8.23749 10.9381 7.96791 
        11.2077C7.69833 11.4773 7.54688 11.8429 
        7.54688 12.2241V12.9429H7.1875C6.04412 12.9441 4.94791 13.3988 
        4.13942 14.2073C3.33092 15.0158 2.87619 
        16.112 2.875 17.2554V34.5C2.87619 35.6434 3.33092 36.7396 4.13942 
        37.5481C4.94791 38.3566 6.04412 38.8113 
        7.1875 38.8125H38.8125C39.9559 38.8113 41.0521 38.3566 41.8606 
        37.5481C42.6691 36.7396 43.1238 35.6434 43.125 
        34.5V17.25C43.1238 16.1066 42.6691 15.0104 41.8606 14.2019C41.0521 
        13.3934 39.9559 12.9387 38.8125 12.9375ZM23 
        33.0625C21.2941 33.0625 19.6266 32.5567 18.2082 31.6089C16.7898   
        30.6612 15.6843 29.3142 15.0315 27.7381C14.3787 
        26.1621 14.2079 24.4279 14.5407 22.7548C14.8735 21.0818 15.695 
        19.5449 16.9012 18.3387C18.1074 17.1325 19.6443 16.311 
        21.3173 15.9782C22.9904 15.6454 24.7246 15.8162 26.3006 16.469C27.8767 
        17.1218 29.2237 18.2273 30.1714 19.6457C31.1192 
        21.0641 31.625 22.7316 31.625 24.4375C31.6224 26.7242 30.7128 28.9165 29.0959 
        30.5334C27.479 32.1503 25.2867 33.0599 
        23 33.0625Z" fill="black"/>
      </svg>
      <p class="font-bold text-base xl:text-xl">
        1 ЭТАП: Отправляете фотографию <br>
        или эскиз
      </p>
    </div>
    <div class="flex inline-block text-left xl:mr-6 mt-2 space-x-2 items-center">
      <p class="text-sm xl:text-base">Выбрав любимое изделие, вы отправляете <br>
        нам фотографию или чертеж с описанием  <br>
        технического задания в наш чат. Потом <br>
        с вами связывается тех. специалист.
      </p>
    </div>
    <div class="flex inline-block text-left mt-4 mr-24 xl:mr-32 space-x-3 items-center">
      <!-- prettier-ignore   -->
      <svg width="41" height="41" viewBox="0 0 41 41" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9375 27.4367L11.5312 21.0292L13.3417 19.2188L17.9375 23.8133L27.6558 
      14.0938L29.4688 15.9067L17.9375 27.4367Z" fill="black"/>
      <path d="M20.5 2.5625C16.9523 2.5625 13.4843 3.61452 10.5345 5.58551C7.58466 7.55651 
      5.28557 10.358 3.92792 13.6356C2.57027 16.9133 2.21505 20.5199 2.90717 23.9994C3.59929 
      27.479 5.30768 30.6751 7.81628 33.1837C10.3249 35.6923 13.521 37.4007 17.0006 38.0928C20.4801 
      38.785 24.0867 38.4297 27.3644 37.0721C30.642 35.7144 33.4435 33.4153 35.4145 30.4655C37.3855 
      27.5157 38.4375 24.0477 38.4375 20.5C38.4375 15.7427 36.5477 11.1802 33.1837 7.81627C29.8198 
      4.45234 25.2573 2.5625 20.5 2.5625ZM20.5 35.875C17.4591 35.875 14.4865 34.9733 11.9581 
      33.2838C9.42971 31.5944 7.45906 29.1932 6.29536 26.3838C5.13166 23.5743 4.82719 
      20.4829 5.42043 
      17.5005C6.01368 14.518 7.47801 11.7785 9.62824 9.62823C11.7785 7.478 14.518 6.01367 
      17.5005 5.42043C20.483 4.82718 23.5744 5.13165 26.3838 6.29535C29.1932 7.45905 31.5944 
      9.4297 33.2839 11.9581C34.9733 14.4865 35.875 17.4591 35.875 20.5C35.875 24.5777 34.2551 
      28.4884 31.3718 31.3718C28.4884 34.2551 24.5777 35.875 20.5 35.875Z" fill="black"/>
      </svg>
      <p class="font-bold text-base xl:text-xl">
        2 ЭТАП: Оформление заказа
      </p>
    </div>
    <div class="flex inline-block text-left mt-2 xl:mr-6 space-x-2 items-center">
      <p class="text-sm xl:text-base">После отправки вашего изделия, вы<br>
        оплачиваете предоплату и ожидаете когда<br>
        вам доставят, тестовый или базовый<br>
        образец вашего кроя
      </p>
    </div>
    <div class="flex inline-block text-left mt-4 space-y-2 mr-28 xl:mr-36 space-x-2 items-center">
      <!-- prettier-ignore   -->
      <svg width="43" height="43" viewBox="0 0 43 43" fill="none"
      xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.2575 16.2244C18.1896 16.12 18.1041 16.0281 18.0048 15.953C17.8978 15.8745 
        17.7763 15.818 17.6473 15.7867C17.5183 15.7554 17.3844 15.7498 17.2533 15.7704C17.1222 
        15.7909 16.9964 15.8372 16.8832 15.9064C16.77 15.9757 16.6716 16.0667 16.5937 
        16.1741C16.5157 16.2815 16.4598 16.4033 16.429 16.5324C16.3982 16.6615 16.3933 16.7954 
        16.4144 16.9264C16.4355 17.0574 16.4823 17.183 16.5521 17.2959C16.6218 17.4088 16.7132 
        17.5068 16.821 17.5843C17.2268 17.8799 17.771 17.8087 18.1177 17.4633L8.59993 
        30.5864L5.63696 41.3337L14.9317 35.1834L36.0998 6.00383L29.7627 1.4082L27.7201 
        4.2247C27.603 3.73735 27.3192 3.30625 26.9179 3.00592C26.6849 2.83635 26.4207 2.71443 
        26.1404 2.64716C25.8602 2.57989 25.5694 2.56859 25.2848 2.61391C25.0002 2.65922 24.7273 
        2.76026 24.4818 2.91123C24.2363 3.06221 24.023 3.26014 23.8542 3.4937C19.7799 9.11327 
        22.0415 10.6102 18.2575 16.2244ZM13.5946 33.7926L8.81493 36.9571L10.3374 
        31.4249L13.5946 33.7926ZM19.229 15.9368C22.1611 11.6046 21.8816 10.4301 
        24.3917 6.58433C24.8082 6.86652 25.2839 6.96864 
        25.7516 6.94445L19.229 15.9368Z"
          fill=" black "
        />
      </svg>
      <p class="font-bold text-base xl:text-xl">
        3 ЭТАП: Корректировки и <br>
        исправления изделия
      </p>
    </div>
    <div class="flex inline-block text-left xl:mr-6 mt-2 space-x-2 items-center">
      <p class="text-sm xl:text-base">Выбрав любимое изделие, вы отправляете <br>
        нам фотографию или чертеж с описанием  <br>
        технического задания в наш чат. Потом <br>
        с вами связывается тех. специалист.
      </p>
    </div>
    <div class="flex inline-block text-left mt-4 space-y-2 mr-14 space-x-2 items-center">
      <!-- prettier-ignore   -->
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M23 30.1875C26.1756 30.1875 28.75 27.6131 
        28.75 24.4375C28.75 21.2619 26.1756 18.6875 23 
        18.6875C19.8244 18.6875 17.25 21.2619 17.25 24.4375C17.25 
        27.6131 19.8244 30.1875 23 30.1875Z" fill="black"/>
        <path d="M38.8125 12.9375H33.5117C33.2422 12.9375 32.908 
        12.7632 32.6474 12.4883L30.3169
        8.81008C30.28 8.7517 30.2389 8.69614 30.1938 8.64387C29.3888 
        7.705 28.3008 7.1875 27.1328 7.1875H18.8672C17.6992 7.1875 
        16.6112 7.705 15.8062 8.64387C15.7611 8.69614 15.72 8.7517 
        15.6831 8.81008L13.3526 12.4937C13.1531 12.7111 12.8728 12.9429 
        12.5781 12.9429V12.2241C12.5781 11.8429 12.4267 11.4773 12.1571 
        11.2077C11.8875 10.9381 11.5219 10.7866 
        11.1406 10.7866H8.98438C8.60313 10.7866 8.23749 10.9381 7.96791 
        11.2077C7.69833 11.4773 7.54688 11.8429 
        7.54688 12.2241V12.9429H7.1875C6.04412 12.9441 4.94791 13.3988 
        4.13942 14.2073C3.33092 15.0158 2.87619 
        16.112 2.875 17.2554V34.5C2.87619 35.6434 3.33092 36.7396 4.13942 
        37.5481C4.94791 38.3566 6.04412 38.8113 
        7.1875 38.8125H38.8125C39.9559 38.8113 41.0521 38.3566 41.8606 
        37.5481C42.6691 36.7396 43.1238 35.6434 43.125 
        34.5V17.25C43.1238 16.1066 42.6691 15.0104 41.8606 14.2019C41.0521 
        13.3934 39.9559 12.9387 38.8125 12.9375ZM23 
        33.0625C21.2941 33.0625 19.6266 32.5567 18.2082 31.6089C16.7898   
        30.6612 15.6843 29.3142 15.0315 27.7381C14.3787 
        26.1621 14.2079 24.4279 14.5407 22.7548C14.8735 21.0818 15.695 
        19.5449 16.9012 18.3387C18.1074 17.1325 19.6443 16.311 
        21.3173 15.9782C22.9904 15.6454 24.7246 15.8162 26.3006 16.469C27.8767 
        17.1218 29.2237 18.2273 30.1714 19.6457C31.1192 
        21.0641 31.625 22.7316 31.625 24.4375C31.6224 26.7242 30.7128 28.9165 29.0959 
        30.5334C27.479 32.1503 25.2867 33.0599 
        23 33.0625Z" fill="black"/>
      </svg>
      <p class="font-bold text-base xl:text-xl">
        4 ЭТАП: Подтверждение, что изде-<br>
        лие готово
      </p>
    </div>
    <div class="flex inline-block text-left mt-2 xl:mr-6 space-x-2 items-center">
      <p class="text-sm xl:text-base">Выбрав любимое изделие, вы отправляете <br>
        нам фотографию или чертеж с описанием  <br>
        технического задания в наш чат. Потом <br>
        с вами связывается тех. специалист.
      </p>
    </div>
  </div>
</template>
