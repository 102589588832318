import { createApp } from "vue";
import App from "./App.vue";
import "./assets/main.css";
import AOS from "aos";
import "aos/dist/aos.css";
const app = createApp(App);

// Инициализация библиотеки AOS для плавного появления текста при прокрутке.
AOS.init();
// Кастомная директива для наблюдения за скроллом
app.directive("scroll-animation", {
  mounted(el) {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          el.classList.add("opacity-100"); // Добавляем класс видимости
        } else {
          el.classList.remove("opacity-100"); // Удаляем класс видимости
        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      threshold: 0.1, // Пороговое значение видимости (от 0 до 1)
    });
    observer.observe(el);
  },
});

createApp(App).mount("#app");
